@import "@uxu/design-system/style/mixins";

.wrapper {
  width: 100%;
  display: flex;
}

.menu {
  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;
  justify-content: flex-start;
  border-right: var(--uxu-border-default);
  padding: var(--uxu-space-large) var(--uxu-space-default) 0 0;

  .desktop {
    display: none;

    @include media(m) {
      display: flex;
    }
  }

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: var(--uxu-space-large);

    li {
      :global(.active) {
        color: var(--uxu-color-link-hover);
        cursor: no-drop;

        svg {
          color: var(--uxu-color-link-hover);
        }
      }
    }
  }

  button {
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
  }

  a, button {
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: all var(--uxu-motion-default);

    svg {
      height: 1.8rem;
    }
  }
}

.content {
  width: calc(100% - 4rem);
  display: flex;
  position: relative;
  flex-direction: column;
  padding-left: var(--uxu-space-default);
}