.wrapper {
  width: 100%;
  display: flex;
  list-style: none;
  overflow-y: scroll;
  gap: var(--uxu-space-large);
  padding: var(--uxu-space-large) var(--uxu-space-default) var(--uxu-space-default) 0;
  border-bottom: var(--uxu-border-default);

  button {
    cursor: pointer;
    background: none;
    color: var(--uxu-color-link-default);
    margin: 0;
    padding: 0;
    width: max-content;
    height: max-content;

    svg {
      height: 1.8rem;
    }

    &:hover {
      color: var(--uxu-color-link-hover);
    }
  }

  li {
    svg {
      height: 1.8rem;
      transition: color 0.3s ease;
    }

    &:hover svg {
      color: var(--uxu-color-link-hover);
    }

    .active {
      cursor: no-drop;

      svg {
        color: var(--uxu-color-link-hover);
      }
    }
  }
}