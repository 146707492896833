@import "@uxu/design-system/style/mixins";

.wrapper {
  position: fixed;
  inset: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0;
  outline: none;
}

.container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 30rem;
  min-height: 20rem;
  padding: var(--uxu-space-default);
  gap: var(--uxu-space-default);
  border-radius: var(--uxu-radii-default);
  background: var(--uxu-color-background-secound);
  border: var(--uxu-border-default);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .header {
    width: 100%;
     p {
       font-weight: bold;
     }
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--uxu-space-default);

    button {
      width: 100%;
    }
  }

  @include media('m') {
    min-width: 60rem;
  }
}


.networks {
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: var(--uxu-space-default);

  li {
    display: flex;
    justify-content: center;

    button {
      width: 100%;

      span {
        font-weight: bold;
        font-size: var(--uxu-font-size-mini);
        color: var(--uxu-color-text-default);
      }
    }
  }
}