@import "../../../style/mixins";

.wrapper {
  background: red;
  display: flex;
  width: 20rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: var(--uxu-radii-default);
  background: var(--uxu-color-background-secound);
  border: var(--uxu-border-default);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @include media('m') {
    width: 60rem;
  }
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  width: 100%;
  padding:  var(--uxu-space-large) var(--uxu-space-default);
}

.footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: var(--uxu-space-default);
  background: rgba(var(--uxu-color-background-default-rgba), 0.6);
  border-top: var(--uxu-border-default);
  padding: var(--uxu-space-default);
}