@import '@uxu/design-system/style/mixins';

.wrapper {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  padding: var(--uxu-space-large) 0 var(--uxu-space-default) 0;
}

.wrapperData {
  padding: var(--uxu-space-default);
  border-radius: var(--uxu-radii-default);
  background-color: var(--uxu-color-background-secound);

  form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    label {
      @include media('m') {
        width: max-content;
      }
    }
    button {
      width: 100%;

      @include media('m') {
        width: max-content;
      }
    }
  }
}
