@import "@uxu/design-system/style/mixins";

.section {
  width: 100%;
  position: relative;
  padding: var(--uxu-space-default) 0;
  border-radius: var(--uxu-radii-default);
}

.containerChildren {
  display: flex;
  justify-content: flex-end;
  gap: var(--uxu-space-default);
  padding-bottom: var(--uxu-space-default);

  * {
    flex-shrink: 0;
    max-width: max-content;
  }
}

.container {
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  gap: var(--uxu-space-default);

  @include media('s') {
    grid-template-columns: repeat(2, 1fr);
  }
  @include media('m') {
    grid-template-columns: repeat(3, 1fr);
  }
  @include media('l') {
    grid-template-columns: repeat(4, 1fr);
  }
}

.file {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  border: var(--uxu-border-default);
  border-radius: var(--uxu-radii-default);
  background-color: var(--uxu-color-background-secound);
}

.cover {
  width: 100%;
  height: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: var(--uxu-color-background-third);

  svg {
    width: 4rem;
    height: 4rem;
    color: var(--uxu-color-link-hover);
  }
}

.data {
  display: flex;
  flex-direction: column;
  gap: var(--uxu-space-small);
  padding: var(--uxu-space-default) var(--uxu-space-small);

  p {
    overflow: hidden;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--uxu-color-text-header);
  }

  span {
    width: max-content;
    font-size: var(--uxu-font-size-small);
    padding: var(--uxu-space-micro);
    border-radius: var(--uxu-radii-default);
    background-color: var(--uxu-color-background-third);
  }
}