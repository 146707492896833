@import "@uxu/design-system/style/mixins";

.section {
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  gap: var(--uxu-space-default);
  padding: var(--uxu-space-default);
  border: var(--uxu-border-default);
  border-radius: var(--uxu-radii-default);
  background-color: var(--uxu-color-background-secound);
}

.header {
  width: 100%;
  max-width: 100%;
  display: grid;
  grid-template-columns: 100%;
  padding-bottom: var(--uxu-space-default);
  border-bottom: var(--uxu-border-default);

  @include media('m') {
    grid-template-columns: repeat(2, 50%);
  }

  .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media('m') {
      align-items: flex-end;
    }
  }

  strong {
    color: var(--uxu-color-text-header);

    span {
      color: var(--uxu-color-red-5);
    }
  }

  p {
    font-size: var(--uxu-font-size-small);
  }
}

.wrapperInputs {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--uxu-space-default);
  border-bottom: var(--uxu-border-default);
  padding-bottom: var(--uxu-space-default);
}


.options {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: var(--uxu-space-default);

  button {
    width: 100%;
  }

  @include media("s") {
    align-items: center;
    justify-content: flex-end;

    button {
      width: max-content;
    }
  }
}

.manual {
  margin-right: auto;
  font-size: var(--uxu-font-size-mini);
}