@import "@uxu/design-system/style/mixins";

.section {
  position: relative;
  height: 100%;
}

.gridContainer {
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  margin: var(--uxu-space-default) 0;

  @include media('m') {
    grid-template-columns: calc(100% - 30rem) 30rem;
  }
}

.childrenContainer {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--uxu-space-default);

  @include media('m') {
    padding-right: var(--uxu-space-default);
  }
}

.infoRail {
  height: 100%;
  display: block;
}

.infoContainer {
  top: 8rem;
  display: flex;
  gap: var(--uxu-space-default);
  flex-direction: column;
  position: sticky;
  padding: var(--uxu-space-default) 0;

  @include media('m') {
    padding: 0;
  }

  .contentWrapper {
    gap: var(--uxu-space-default);
    padding: var(--uxu-space-default);
    border: var(--uxu-border-default);
    border-radius: var(--uxu-radii-default);
    background-color: var(--uxu-color-background-secound);

    .headerWrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      border-bottom: var(--uxu-border-default);
      padding: 0 var(--uxu-space-small) var(--uxu-space-small);

      span {
        font-weight: bold;
        font-size: var(--uxu-font-size-small);
      }
    }

    .coverWrapper {
      width: 16rem;
      height: 16rem;
      display: flex;
      overflow: hidden;
      align-items: center;
      justify-content: center;
      border-radius: var(--uxu-radii-default);
      background-color: rgba(var(--uxu-color-background-default-rgba), 0.3);
      border: var(--uxu-border-default);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    ul {
      margin: 0;
      list-style: none;
      padding-top: var(--uxu-space-default);

      li {
        padding: var(--uxu-space-small) 0;
        display: flex;

        p, strong {
          white-space: none;
          font-size: var(--uxu-font-size-small);

          span {
            font-size: var(--uxu-font-size-mini)
          }
        }
      }
    }
  }
}