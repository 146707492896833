@import "@uxu/design-system/style/mixins";

.section, .wrapperButtons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section {
  min-height: 100vh;
  flex-direction: column;
}

.wrapperButtons {
  width: 100%;
  flex-wrap: wrap;
  gap: var(--uxu-space-large);
  padding: calc(2 * var(--uxu-space-large)) 0 var(--uxu-space-large) 0;

  @include media(s) {
    padding: calc(2 * var(--uxu-space-large)) 0 calc(3 * var(--uxu-space-large)) 0;
  }
}
