@import "@uxu/design-system/style/mixins";

.header {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  gap: var(--uxu-space-default);
  padding: var(--uxu-space-large) 0 var(--uxu-space-default);

  @include media('m'){
    flex-wrap: nowrap;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    border-bottom: var(--uxu-border-default);
  }
}

.left, .right {
  width: 100%;
  display: flex;
  gap: var(--uxu-space-default);

  @include media('m') {
    width: 50%;
  }
}

.left {
  flex-direction: column;
  gap: var(--uxu-space-small);
}

.right {
  max-width: 100%;

  @include media('s') {
    align-items: flex-end;
    justify-content: flex-end;
  }
}
